'use strict';


import PriceCalculator from "./PriceCalculator";

document.addEventListener('DOMContentLoaded', () => {

    new PriceCalculator(document.querySelector('[data-price-calc]') as HTMLDivElement);

});

