'use strict';

export default class PriceCalculator {

    #container: HTMLDivElement;
    #range: HTMLInputElement;
    #buttonsTariff: NodeListOf<HTMLButtonElement>;
    #cssActiveTariff: string;
    #min: number;
    #max: number;
    #labelVisits: HTMLElement;
    #labelPricePerVisit: HTMLElement;

    #tariff: string = 'mini';

    #prices: { standard: number; mini: number; basic: number; profi: number };
    #visits: number = 0;
    #limitVisits: { standard: number; mini: number; basic: number; profi: number };

    #locale:string = 'cs-CZ';

    /**
     * expects elements with attributes:
     * <div>
     *   <span data-visits></span> návštěv měsíčně
     *   <span data-price></span> Kč/visit
     *   <button type="button" data-action-tariff="mini">mini</button>
     *   <button type="button" data-action-tariff="basic">basic</button>
     *   <button type="button" data-action-tariff="standard">standard</button>
     *   <button type="button" data-action-tariff="profi">profi</button>
     *   <input type="range" min="0" max="50000" value="0">
     *   <span data-min></span>
     *   <span data-label-basic></span>
     *   <span data-label-standard></span>
     *   <span data-label-profi></span>
     *   <span data-max></span>
     * </div>
     */
    constructor(div: HTMLDivElement) {

        this.#container = div;
        this.#cssActiveTariff = 'js-active';
        this.#range = div.querySelector('[type="range"]') as HTMLInputElement;
        this.#buttonsTariff = div.querySelectorAll<HTMLButtonElement>('[data-action-tariff]');
        this.#labelVisits = div.querySelector('[data-visits]') as HTMLElement;
        this.#labelPricePerVisit = div.querySelector('[data-price]') as HTMLElement;

        this.#limitVisits = {
            mini: 500,
            basic: 5000,
            standard: 15000,
            profi: 25000
        };
        this.#prices = {
            mini: 990,
            basic: 1490,
            standard: 2490,
            profi: 4490
        }

        this.#min = parseInt(this.#range.getAttribute('min') as string);
        this.#max = parseInt(this.#range.getAttribute('max') as string);
        this.#visits = parseInt(this.#range.value);

        this.#range.addEventListener('input', () => {
            const visits = parseInt(this.#range.value);
            if (visits > this.#limitVisits.profi) {
                this.#tariff = 'profi';
            } else if (visits > this.#limitVisits.standard) {
                this.#tariff = 'standard';
            } else if (visits > this.#limitVisits.basic) {
                this.#tariff = 'basic';
            } else {
                this.#tariff = 'mini';
            }
            this.setTariff(this.#tariff);
            this.setVisits(visits);
            // this.actionCalculate.bind(this));
            this.calculate();
        });

        this.#buttonsTariff.forEach((button) => {
            button.addEventListener('click', event => {
                event.preventDefault();
                const tariff = button.dataset.actionTariff as string;
                this.setTariff(tariff);
                // @ts-ignore
                this.setVisits(this.#limitVisits[tariff] || 0);
                this.calculate();
            });
        });

        // init
        (div.querySelector('[data-label-min]') as HTMLElement).innerText = this.#min.toLocaleString(this.#locale);
        (div.querySelector('[data-label-basic]') as HTMLElement).innerText = this.#limitVisits.basic.toLocaleString(this.#locale);
        (div.querySelector('[data-label-standard]') as HTMLElement).innerText = this.#limitVisits.standard.toLocaleString(this.#locale);
        (div.querySelector('[data-label-profi]') as HTMLElement).innerText = this.#limitVisits.profi.toLocaleString(this.#locale);
        (div.querySelector('[data-label-max]') as HTMLElement).innerText = this.#max.toLocaleString(this.#locale);

        this.setTariff(this.#tariff);
        this.setVisits(this.#visits);
        this.calculate();
    }

    private calculate() {
        // @ts-ignore
        const priceTariff = this.#prices[this.#tariff] || 0,
            pricePerVisit = this.#visits <= 0 ? 0 : priceTariff / this.#visits;

        this.#labelPricePerVisit.innerText = pricePerVisit.toFixed(2).toString();
    }

    private setTariff(tariff: string) {
        this.#tariff = tariff;
        this.#buttonsTariff.forEach((button) => {
            if (button.dataset.actionTariff === this.#tariff) {
                button.classList.add(this.#cssActiveTariff);
            } else {
                button.classList.remove(this.#cssActiveTariff);
            }
        });
    }

    private setVisits(visits: number) {
        this.#visits = visits;
        this.#range.value = visits.toString();
        this.#labelVisits.innerText = visits.toLocaleString(this.#locale);
    }
}
